import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';

// import useLocale from '../../hooks/useLocale';

import imgFilterDarker from '../../assets/images/meet-the-team/15709.svg';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import '../../assets/styles/pages/meet-the-team-page.scss';

const MeetTheTeam = ({ data }) => {
  // const { enLocale } = useLocale({ matchPathName: '/en/' }); // TODO: Es version of the team
  const allPrismicTeam = data.allPrismicTeam.edges;
  console.log('allPrismicTeam: ', allPrismicTeam);
  const initialPerson = allPrismicTeam?.[0]?.node?.id;

  const [activePersonID, setActivePersonID] = useState(initialPerson);
  const activePerson = allPrismicTeam?.filter(({ node }) => node.id === activePersonID)?.[0]?.node;

  return (
    <Layout
      title={'The Best Spanish Teachers & Tutors in Barcelona | 2024 Team'}
      description={
        'Come and meet your friendly and professional Spanish teachers and the Iberia Language Academy Management Team. '
      }
    >
      <div className="meet-the-team-page">
        <section className="first-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <h1 className="c-title-42 first-section__title">The ILA Team</h1>
              </div>
              <div className="col-md-6">
                <p className="c-text-18 first-section__description">
                  At our language school, we have a dedicated team of professionals who are
                  passionate about teaching Spanish and helping our students achieve their language
                  goals. With diverse backgrounds and experiences, our team brings a wealth of
                  expertise and enthusiasm to create a vibrant learning environment. Get to know the
                  faces behind our school:
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="second-section">
          <div className="container ">
            {/* <div className="row">
              <div className="col-12">
                <i className="alert alert-warning">More content is coming soon...</i>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-4 order-md-first order-last">
                <div className="block_l">
                  <p className="person__position">{activePerson?.data?.position?.text}</p>
                  <h2 className="person__name c-title-32">{activePerson?.data?.name?.text}</h2>
                  <div
                    className="person__subtitle"
                    dangerouslySetInnerHTML={{ __html: activePerson?.data?.description?.html }}
                  />
                  {/* <p className="person__description c-text-16">
                    Nowadays you won’t see him in the classroom but tucked away in the staff room,
                    working on developing the school and generally looking for ways to improve the
                    student experience.{' '}
                  </p> */}
                  {activePerson?.data?.publications?.html?.length === 0 ? null : (
                    <>
                      <p className="person__works">
                        <FormattedMessage id="team.sec2.publication" />:
                      </p>
                      <ul
                        className="person__list"
                        dangerouslySetInnerHTML={{ __html: activePerson?.data?.publications?.html }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-8 order-md-last order-first">
                <div className="block_r">
                  {allPrismicTeam?.length > 0 &&
                    allPrismicTeam.map(({ node }) => (
                      <div
                        key={node.id}
                        className={`person__link ${
                          activePersonID === node.id ? 'person__link--active' : ''
                        }`}
                        onClick={() => setActivePersonID(node.id)}
                      >
                        {node?.data?.photo && (
                          <img
                            src={node.data.photo.url}
                            className="main-img"
                            alt={node?.data?.name?.text}
                          />
                        )}
                        <img src={imgFilterDarker} className="filter" alt="filter" />
                        <div className="text">
                          <p className="link__name">{node?.data?.name?.text}</p>
                          <p className="link__position">{node?.data?.position?.text}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicTeam(
      sort: { order: ASC, fields: last_publication_date }
      filter: { data: { published: { eq: true } } }
    ) {
      edges {
        node {
          id
          data {
            description {
              html
            }
            name {
              text
            }
            photo {
              url
            }
            position {
              text
            }
            publications {
              html
            }
          }
        }
      }
    }
  }
`;

export default MeetTheTeam;
